@import '../../fonts/fonts.scss';


$pbgc:white;
$sbgc:#f7f8fb;
$main-font: 'TT Hoves';
$nav-height: 100px;
$black:#111;
$font-bold:500;
$h4:1.4rem;
$h1:7rem;
$h2:4rem;
$dark-grey:rgb(43, 43, 43);
$offset-white:#f7f8fb;

%align-center {
    display: flex;
    align-items: center;
}

%max-width {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
}

%bgfont {
    position: absolute;
    font-family: monospace;
    z-index: -10;
    font-size: 140px;
    -webkit-text-stroke: 1px rgb(221, 221, 221);
    color: transparent;
    font-style: italic;
    // line-height: calc(var($h1)*2.07);
    letter-spacing: -20px;
}