@import 'variables';
@import 'media-queries.scss';

.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $nav-height;
    z-index: 10;

    &__wrapper {
        @extend %align-center;
        justify-content: space-between;
        height: 100%;
        width: 90%;
        margin: 0 auto;
        max-width: 1280px;



        .logo {
            height: 35%;

            img {
                height: 100%;
            }
        }



        .nav {
            @include tablet {
                display: none;
            }

            ul {
                @extend %align-center;
                list-style: none;

                .nav-links {
                    text-decoration: none;
                    color: $black;

                    li {
                        margin-left: 3rem;
                        font-weight: $font-bold;
                    }
                }

            }
        }
    }

}