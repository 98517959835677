@import 'component/variables.scss';
@import 'component/About.scss';
@import 'component/customcursor.scss';
@import 'component/Featured.scss';
@import 'component/Footer.scss';
@import 'component/Gallery.scss';
@import 'component/Header.scss';
@import 'component/Navbar.scss';
@import 'component/featuredWork.scss';
@import 'component/media-queries.scss';


@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');


* {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

body {
    font-family: $main-font;
    color: $black;
    background-color: $pbgc;
    -webkit-font-smoothing: antialiased;
    cursor: none;


    @include mobile {
        width: 100vw;
        overflow-x: hidden;
    }
}

.loader-wrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #191919;
    color: #dbd8d6;

    h1 {
        font-size: 1.2vw;
        text-transform: uppercase;
        font-family: 'Pacifico', cursive;
        font-weight: 600;
        font-style: italic;
        margin-top: 20px;
    }

    img {
        height: 6vw;

        @include mobile {
            height: 20vw;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }


    }


}

.absolute {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: 1000;
}


body::-webkit-scrollbar {
    width: 0.4em;
}

body::-webkit-scrollbar-thumb {
    background-color: #3a3a3a;
    /*outline: 1px solid slategrey;*/
    border-radius: 20px;
}